<template>
  <div class="my-second replace ">
    <div class="replace-main">
      <Head :title="$t('wallet.exchange')" :show="true" :bg="true" />
      <div class="content" >
        <ul class="content-list"  v-if="list.length>0">
          <li v-for="(l,i) in list" :key="i">
            <template v-if="(l.bt_amount-l.bt_value)!=0">
              <div class="fixed-icon1 fixed-text"  v-if="lang=='cn'">{{$t('wallet.giveaway')}}</div>
              <div class="fixed-icon2 fixed-text"  v-else>{{$t('wallet.giveaway')}}</div>
            </template>
            
            <van-checkbox v-model="l.checked" @click="changeTotal(l,i)" class="mycheck" checked-color="#DF561C"></van-checkbox>
            <div class="yhq">
              <p class="yhq-left">
                <span class="span1">฿</span>
                <span class="span2">{{l.th_amount | number_format}}</span>
              </p>
              <div class="yhq-right">
                <p class="p1">
                  <span>{{$t('wallet.exchange')}}</span>
                  <span>฿</span>
                  <span>{{l.th_amount | number_format}}</span>
                </p>
                <div class="p2">
                  <span >{{$t('wallet.available')}}</span>
                  <p class="belt-img">
                    <img src="@/assets/wallet/wallet-logo.png" alt="">
                    <span>{{l.bt_value | number_format}}</span>
                  </p>
                  
                  <p class="belt-img" v-if="(l.bt_amount-l.bt_value)!=0">
                    <span class="add-icon">+</span>
                    <img src="@/assets/wallet/wallet-logo.png" alt="">
                    <span>{{(l.bt_amount-l.bt_value)  | number_format }}</span>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="list.length==0" class="no-box">
          <img class="juan-no" src="../../assets/images/xuqiu-no.png" alt="">
          <p class="no-text">{{$t('good.none_date')}}</p>
        </div>
      </div>
      <div class="footer"  >
        <p class="total">
          <span class="span1">{{$t('wallet.total')}} :</span>
          <!-- <span class="span2">$ {{big}}+{{small}}</span>
          <span class="span3"> ≈ </span> -->
          <span class="span4"> ฿ {{total | number_format}}</span>
        </p>
        <van-button class="pay" @click="secshow=true">{{$t('wallet.pay_now')}}</van-button>
      </div>
    </div>

     <!-- 支付成功 支付失败  -->
     <van-popup class="login-popup pay-one" v-model="show" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="show=false" />
            </div>
            <div class="pay-two" v-if="pay_success">
                <img :src="require('@/assets/images/pay-success.png')" alt="">
                <p>{{$t('wallet.pay')}}{{$t('wallet.success')}}</p>
            </div>
            <div class="pay-two" v-else >
                <img :src="require('@/assets/images/pay-fail.png')" alt="">
                <p>{{$t('wallet.pay')}}{{$t('wallet.fail')}}</p>
            </div>
            <van-button class="kown-btn" @click="show=false">{{$t('wallet.kown')}}</van-button>
        </div>
    </van-popup>

    <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one" v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form label-width="140" class="pay-form" ref="payform" @submit="gotoexchange">
                <van-field
                v-model="secpass"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="pin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[{ required: true, message:$t('login.ptian')}]"
                >

                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="submitForm">{{$t('login.confirm')}}</van-button>
            </div>
        </div>
    </van-popup>
  </div>
</template> 
<script>
import {mapState,mapMutations} from "vuex"
import {randomString} from "@/common"
import {Notify} from "vant"
export default {
  name: "wallet-replace",
  data() {
    return {
      lang:localStorage.getItem("langData") || 'th',
      current:-1,
      checked: false,
      show:false,
      secshow:false,
      pt3:"password",
      secpass:'',
      obj:{},
      list:[],
      big:0,
      small:0,
      ids:0,
      pay_success:true
    };
  },
  computed:{
      ...mapState(['taiprice']),
      total(){
        // return (this.big+this.small) * this.taiprice
        return this.big;
      }
  },
  watch:{
    secshow(v){
        this.pt3 = "password"
        this.secpass = "";
    }
  },
  methods:{
    changeTotal(v,index){
      this.ids = v.id;
      this.big = v.th_amount * 1;
      // this.small = v.bt_amount *1 - v.th_amount * 1;
      this.small = v.bt_amount *1;
      
      this.current = index;
      this.list = this.list.map(item=>{
        return {...item,checked:v.id==item.id}
      })
    },
    ...mapMutations(['changetaiprice']),
    changePassType3(){
          this.pt3 = this.pt3 == "password"?"text":"password"
    },
    submitForm(){
      this.$refs.payform.submit()
    },
    gotoexchange(value){
      // exchange_id ???  多个链接怎么给  
      this.$ajax.thexchangebt({
        exchange_id:this.ids,
        pin:value.pin,
        th_amount:this.total,
        form_token:randomString()
      })
      .then(res=>{
          this.secshow = false;
          this.secpass = ""
          this.show = true;
          if(res.code==200){
              // Notify({ type: 'success', message: res.msg});
              this.pay_success = true;
          }else{
            this.pay_success = false;
          }
      })
    }
  },
  mounted(){
    this.$ajax.thexlist()
    .then(res=>{
        if(res.code==200){
          this.list = res.data.exchangeList
        }
    })


    // if(!this.taiprice){
    //   this.$ajax.taiprice()
    //   .then(res=>{
    //       if(res.code==200){
    //           var taiprice = 1/res.data.thaiprice.split(" ")[0];
    //           this.changetaiprice(taiprice)
    //       }
    //   })
    // }
  }
}
</script>